import { createRef, PureComponent, Fragment, MouseEvent } from 'react';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import { ContextType } from '@ui/components/context';
import LocationPicker from '@ui/components/location-picker';
import DatePicker from '@ui/components/date-picker';
import TimePicker from '@ui/components/time-picker';
import { Locations } from '@ui/components/location-picker/interface';
import { Range } from '@ui/components/date-picker/interface';
import { appendParamsCurrentPage, PageSection } from '@utils';

import { DRIVEHUB_V3_API } from '@config';
import {
  trackLocationOnClear,
  trackLocationSelected,
  trackSearch,
  SearchAction,
  trackLocationExpand,
  trackLocationSearch,
  trackSearchTime,
  trackSearchDate,
  getSearchEventLabel
} from './tracking';
import { SearchBoxState, SearchBoxProps } from './interface';
import SearchBoxTab from './selector';
import 'moment/locale/th';

import './style.scss';

const format = 'YYYY-MM-DD HH:mm';
class SearchBox extends PureComponent<SearchBoxProps, SearchBoxState> {
  constructor(props: SearchBoxProps) {
    super(props);
    this.state = {
      locationID: this.props.locationID || '',
      locations: [],
      bookingBegin: this.props.bookingBegin,
      bookingEnd: this.props.bookingEnd,
      focused: '',
      isLocationPickerOpen: false
    };
  }
  static contextType = ContextType;
  // context!: React.ContextType<typeof ContextType>;
  datePickerRef = createRef<HTMLDivElement>();
  // NOTE: FIX any type
  datePickerComponentRef = createRef<any>();
  // datePickerComponentRef = createRef<DatePicker>();

  componentDidMount() {
    this.fetchLocations();
    window.addEventListener('resize', () => this.handleResponsive(), false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.handleResponsive(), false);
  }

  get currentCity() {
    const { locationID } = this.state;
    if (!locationID) return undefined;
    return this.state.locations.find((city) => city.locations.find((location) => location.id == parseInt(locationID)));
  }

  getLocationName = (city?: Locations) => {
    const { locationID } = this.state;
    if (!locationID) return '';
    const location = city?.locations.find((location) => location.id == parseInt(locationID));
    return location?.name[this.props.prefix];
  };

  fetchLocations() {
    const { vendorID, isUseLocalApi, vendor } = this.props;
    const { searchAPI, v3API } = this.context;
    let isV3 = false;

    if (vendorID && !isUseLocalApi) {
      axios.defaults.baseURL = v3API || DRIVEHUB_V3_API;
      isV3 = true;
    } else {
      axios.defaults.baseURL = searchAPI;
    }

    let param = '';
    if (vendorID && !isUseLocalApi) {
      const sv = vendor ? (typeof vendor.subVendor === 'string' ? [] : vendor.subVendor ?? []) : [];
      const subVendor = sv.filter((id) => id !== vendorID);

      if (subVendor.length > 0) {
        param = `?dealerships=${vendorID},${subVendor.join(',')}`;
      } else {
        param = `?dealerships=${vendorID}`;
      }
    }
    axios.get(`/locations${param}`).then((object) => {
      let cities = object.data as Locations[];
      cities.sort((a: Locations, b: Locations) => a.name.th.localeCompare(b.name.th));
      if (isV3) {
        // If v3 use dh_* instead of id
        cities = [...cities].map((c: Locations) => {
          const lo = c.locations.map((l) => ({ ...l, id: l.dh_location_code }));
          return { ...c, id: c.dh_city_code, locations: lo };
        });
      }
      this.setState({ locations: cities });
    });
  }

  onClickDatePicker = (focused: string, e?: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    if (focused !== '') {
      if (e) trackSearchDate(e, this.props.sectionName);
    }
    this.setState({ focused: focused }, () => {
      this.handleClickDatePicker();
      this.switchNavBarState();
    });
  };

  handleClickDatePicker = () => {
    if (this.state.focused !== '') {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }
  };

  handleOutsideClick = (e: any) => {
    if (this.datePickerRef.current && this.datePickerRef.current.contains(e.target)) return;
    if (!this.datePickerComponentRef.current) return;
    const currentRange: Range = this.datePickerComponentRef.current.range;
    const nextRange = this.datetimeAlign(currentRange);
    this.setState(
      {
        bookingBegin: nextRange.bookingBegin,
        bookingEnd: nextRange.bookingEnd,
        focused: ''
      },
      () => this.handleClickDatePicker()
    );
  };

  handleResponsive = () => {
    const navbarElem = document.getElementById('mainMenu');
    if (!navbarElem) return;
    if (this.props.sectionName !== PageSection.Promotion) {
      const bgElem = document.getElementById('content-section-bg');
      if (!bgElem) return;
      bgElem.style.zIndex = '100';
    }
    if (this.context.isMobile) {
      if (this.state.focused !== '' || this.state.isLocationPickerOpen) {
        navbarElem.style.zIndex = '0';
      } else {
        navbarElem.style.zIndex = '1800';
      }
    } else {
      navbarElem.style.zIndex = '1800';
    }
  };

  onSubmitDatePicker = (range: Range) => {
    const nextRange = this.datetimeAlign(range);
    this.setState(
      {
        bookingBegin: nextRange.bookingBegin,
        bookingEnd: nextRange.bookingEnd,
        focused: ''
      },
      () => {
        this.handleClickDatePicker();
        this.switchNavBarState();
      }
    );
  };

  switchNavBarState = () => {
    const navbarElem = document.getElementById('mainMenu');
    if (!navbarElem) return;
    if (this.props.sectionName !== PageSection.Promotion) {
      const bgElem = document.getElementById('content-section-bg');
      if (!bgElem) return;
      bgElem.style.zIndex = '100';
    }
    if (this.context.isMobile) {
      if (this.state.focused !== '') {
        navbarElem.style.zIndex = '0';
      } else {
        navbarElem.style.zIndex = '1800';
      }
    } else {
      navbarElem.style.zIndex = '1800';
    }
  };

  onFieldSubmit = (range: Range) => {
    const nextRange = this.datetimeAlign(range);
    this.setState({
      ...nextRange,
      focused: ''
    });
  };

  datetimeAlign = (range: Range): Range => {
    const state = {
      bookingBegin: range.bookingBegin,
      bookingEnd: range.bookingEnd
    };
    let bookingBegin = moment(range.bookingBegin, format);
    const bookingEnd = moment(range.bookingEnd, format);

    if (bookingBegin.isBefore(moment())) {
      state.bookingBegin = bookingBegin.format(`YYYY-MM-DD ${moment().hours() + 1}:00`);
      bookingBegin = moment(state.bookingBegin, format);
    }

    if (bookingBegin.format('YYYY-MM-DD') === bookingEnd.format('YYYY-MM-DD')) {
      if (parseInt(bookingEnd.format('HH'), 10) - parseInt(bookingBegin.format('HH'), 10) <= 4) {
        state.bookingEnd = bookingBegin.format(
          `YYYY-MM-DD ${parseInt(bookingBegin.format('HH'), 10) + 4}:${bookingBegin.format('mm')}`
        );
      }
    }
    return state;
  };

  get trackingLabel() {
    const { sectionName, promotionTrackingName, vendor } = this.props;
    switch (sectionName) {
      case PageSection.Home:
        return 'from_homepage';
      case PageSection.Promotion:
        return `${promotionTrackingName}`;
      case PageSection.Inter:
        return `${vendor?.vendorName.toLowerCase().replace(/ /g, '_')}`;
      case PageSection.City:
        return `${this.getLocationName(this.currentCity)}`;
      default:
        return 'undefined';
    }
  }

  onSubmit = () => {
    const { prefix, sectionName } = this.props;
    const { bookingBegin, bookingEnd, locationID } = this.state;

    if (bookingBegin && bookingEnd && locationID) {
      let params = {
        booking_begin: bookingBegin,
        booking_end: bookingEnd,
        location_id: locationID,
        dealers: '',
        from: getSearchEventLabel(sectionName, this.trackingLabel)
      };
      if (this.props.vendorID && !this.props.isUseLocalApi) {
        params = { ...params, dealers: `${this.props.vendorID}` };
      }
      const parsed = Object.keys(params).reduce((p, c) => (p += `${c}=${params[c]}&`), '?');
      const searchURL = `${this.context.searchHost}/${parsed}`;
      const url = appendParamsCurrentPage(searchURL);
      trackSearch(SearchAction.Success, prefix, sectionName, this.trackingLabel, {
        bookingBegin,
        bookingEnd,
        pickupLocation: this.getLocationName(this.currentCity),
        ...(this.currentCity ? { city: this.currentCity } : {})
      });
      window.location.href = url;
    } else {
      trackSearch(SearchAction.Fail, prefix, sectionName, this.trackingLabel);
      alert('โปรดระบุข้อมูลให้ครบถ้วน');
    }
  };

  onLocationChange(locationID: number | undefined) {
    const { sectionName } = this.props;
    this.setState({ locationID: Number(locationID).toString() }, () =>
      trackLocationSelected(this.getLocationName(this.currentCity), sectionName)
    );
  }

  render() {
    const { sectionName } = this.props;
    const { locationID, locations, bookingBegin, bookingEnd, focused } = this.state;
    return (
      <div className="search-box-container">
        <div className="search-box-tab">
          <SearchBoxTab pageSection={sectionName} />
        </div>
        <LocationPicker
          locations={locations}
          locationID={Number(locationID)}
          cityID={Number(this.props.cityID)}
          onChange={(locationID) => this.onLocationChange(locationID)}
          prefix={this.props.prefix}
          isPromotionPage={sectionName === PageSection.Promotion}
          onToggle={(isTogglePicker) => this.setState({ isLocationPickerOpen: isTogglePicker })}
          onClear={(preventTracking) => {
            !preventTracking && trackLocationOnClear(sectionName);
            this.setState({ locationID: '' });
          }}
          onExpand={(city, isExpand) => trackLocationExpand(city, isExpand, sectionName)}
          onSearchSuccess={_.debounce(
            (searchTerm, isSuccess, preventTracking) =>
              !preventTracking && trackLocationSearch(searchTerm, isSuccess, sectionName),
            1000
          )}
        />
        <div className="pair" ref={this.datePickerRef}>
          <DatePicker
            ref={this.datePickerComponentRef}
            bookingBegin={bookingBegin}
            bookingEnd={bookingEnd}
            focused={focused}
            isActive={focused === 'bookingBegin' || focused === 'bookingEnd'}
            onSubmit={(range: Range) => this.onSubmitDatePicker(range)}
            onClose={() => this.onClickDatePicker('')}
          >
            <div className="pair__datepicker">
              <div onClick={(e) => this.onClickDatePicker('bookingBegin', e)} data-event-label="pickup_date">
                <p className="label">วันที่รับรถ</p>
                <div className="children">{moment(bookingBegin, format).format('DD MMM YYYY')}</div>
                <i className="icon-calendar" />
              </div>
              <div onClick={(e) => this.onClickDatePicker('bookingEnd', e)} data-event-label="return_date">
                <p className="label">วันที่คืนรถ</p>
                <div className="children">{moment(bookingEnd, format).format('DD MMM YYYY')}</div>
                <i className="icon-calendar" />
              </div>
            </div>
          </DatePicker>
        </div>
        <div className="pair pair--no-margin">
          <div className="pair__timepicker">
            <div data-event-label="pickup_time" onClick={(e) => trackSearchTime(e, sectionName)} className="time-box">
              <TimePicker
                id="timepicker_booking_begin"
                bookingBegin={bookingBegin}
                bookingEnd={bookingEnd}
                onChange={this.onFieldSubmit}
                isBookingBegin={true}
              >
                {(picker) => (
                  <Fragment>
                    <p className="label">เวลารับรถ</p>
                    <div className="children">{picker}</div>
                    <i className="icon-down" />
                  </Fragment>
                )}
              </TimePicker>
            </div>
            <div data-event-label="return_time" onClick={(e) => trackSearchTime(e, sectionName)} className="time-box">
              <TimePicker
                id="timepicker_booking_end"
                bookingBegin={bookingBegin}
                bookingEnd={bookingEnd}
                onChange={this.onFieldSubmit}
                isBookingBegin={false}
              >
                {(picker) => (
                  <Fragment>
                    <p className="label">เวลาคืนรถ</p>
                    <div className="children">{picker}</div>
                    <i className="icon-down" />
                  </Fragment>
                )}
              </TimePicker>
            </div>
          </div>
        </div>
        <div className="submit-container">
          <button
            className="btn btn-block btn-primary dh-animate-pulse"
            data-event-label="from_homepage"
            onClick={this.onSubmit}
          >
            ค้นหารถว่าง
          </button>
        </div>
      </div>
    );
  }
}

export default SearchBox;
