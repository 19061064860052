import { MouseEvent } from 'react';
import moment from 'moment';
import { sendToDataLayer, getTrackingTimestamp, getDefaultEventCategory, PageSection } from '@utils';

export const getSearchEventLabel = (whichSection: PageSection, customText: string) => {
  switch (whichSection) {
    case PageSection.Home:
      return 'from_homepage';
    case PageSection.Promotion:
      return `${customText}`;
    case PageSection.City:
      return `destination_${customText}`;
    case PageSection.Inter:
      return `dealer_${customText}`;
    default:
      return 'undefined';
  }
};

export const trackLocationOnClear = (section: PageSection) => {
  sendToDataLayer(undefined, {
    event_category: getCustomCategory(section),
    event_action: 'clear',
    event_label: section === PageSection.Inter || section === PageSection.City ? 'X buttons' : 'pickup_location'
  });
};
export const trackLocationSelected = (location_name: string, section: PageSection) => {
  sendToDataLayer(undefined, {
    event_category: getCustomCategory(section),
    event_action: 'select',
    event_label: location_name
  });
};

export enum SearchAction {
  Success = 'search_bookings_success',
  Fail = 'search_bookings_fail'
}
interface BookingDetail {
  bookingBegin: string;
  bookingEnd: string;
  pickupLocation: string;
  city?: Locations;
}
export const trackSearch = (
  which: SearchAction,
  prefix: string,
  section: PageSection,
  customText?: string,
  bookingDetail?: BookingDetail
) => {
  let customEvent = undefined;
  if (!bookingDetail)
    return sendToDataLayer(undefined, {
      event_category: getCustomCategory(section),
      event_action: which,
      event_label: getSearchEventLabel(section, customText || 'undefined')
    });
  const { bookingBegin, bookingEnd, city, pickupLocation } = bookingDetail;
  switch (which) {
    case SearchAction.Success:
      customEvent = {
        event_category: getCustomCategory(section),
        event_action: which,
        event_label: getSearchEventLabel(section, customText || 'undefined'),
        search_return_date: `${moment(bookingEnd).locale('en').format('YYYY-MMM-DD')}`,
        search_return_time: `${moment(bookingEnd).locale('en').format('HH:mm:ss')}`,
        search_destination: `${city?.name[prefix]}`,
        search_pickup_date: `${moment(bookingBegin).format('YYYY-MMM-DD')}`,
        search_pickup_time: `${moment(bookingBegin).format('HH:mm:ss')}`,
        search_pickup_location: `${pickupLocation}`,
        search_dealer_type: section === PageSection.Inter ? 'inter' : 'undefined',
        search_dealer_name: section === PageSection.Inter ? customText || 'undefined' : 'undefined',
        search_car_type: 'undefined',
        search_promotion_name: section === PageSection.Promotion ? customText || 'undefined' : 'undefined',
        search_rental_type: 'undefined',
        sort_by: 'undefined',
        search_time_stamp: getTrackingTimestamp()
      };
      break;
    default:
      customEvent = undefined;
  }
  sendToDataLayer(undefined, customEvent);
};

export const trackLocationExpand = (city: Locations, isExpand: boolean, section: PageSection) => {
  sendToDataLayer(undefined, {
    event_category: getCustomCategory(section),
    event_action: isExpand ? 'expand' : 'hide',
    event_label: `${city.name}`
  });
};

export const trackLocationSearch = (searchTerm: string, isSuccess: boolean, section: PageSection) => {
  // const customText = section === PageSection.PromotionPage ? promotionTrackingName || '' : searchTerm;
  // getSearchEventLabel(section, customText)
  sendToDataLayer(undefined, {
    event_category: getCustomCategory(section),
    event_action: isSuccess ? 'search_success' : 'search_fail',
    event_label: searchTerm
  });
};

export const trackSearchTime = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>, section: PageSection) => {
  sendToDataLayer(e, {
    event_category: getCustomCategory(section)
  });
};

export const trackSearchDate = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>, section: PageSection) => {
  sendToDataLayer(e, {
    event_category: getCustomCategory(section)
  });
};

const getCustomCategory = (section: PageSection) => {
  if (section === PageSection.Home)
    return getDefaultEventCategory(section, { customCategory: 'homepage_search_section' });
  if (section === PageSection.Promotion)
    return getDefaultEventCategory(section, { customCategory: 'promotion_search_section' });
  return getDefaultEventCategory(section);
};
