import React, { PureComponent } from 'react';
import moment from 'moment-timezone';
import { TimeFormat, TimePickerProps, Range } from './interface';

moment.tz('Asia/Bangkok');

class TimePicker extends PureComponent<TimePickerProps> {
  get timeSlot() {
    const limit = 24;
    const timeList: TimeFormat[] = [];
    for (let i = 0; i < limit; i++) {
      timeList.push(
        { value: `${i > 9 ? i : '0' + i}:00`, label: `${i}:00` },
        { value: `${i > 9 ? i : '0' + i}:30`, label: `${i}:30` }
      );
    }
    return timeList.splice(12).concat(timeList.slice(0, 12));
  }
  getDate = (dateStr: string, field: string) => {
    const d = dateStr.split(' ')[0];
    let t = dateStr.split(' ')[1];

    const timeSlot = this.getTimeAvailable(field === 'bookingBegin');
    const index = timeSlot.findIndex((slot) => slot.value === t);
    if (index < 0) {
      t = timeSlot[0].value;
    }
    return `${d} ${t}`;
  };
  getTimeAvailable = (isBookingBegin = this.props.isBookingBegin) => {
    const timeList = this.timeSlot.filter((el) => el.value !== '00:00');
    const { bookingBegin, bookingEnd } = this.props;
    const isSameDay = moment(bookingBegin).startOf('days').isSame(moment(bookingEnd).startOf('days'));

    const isToday = moment(bookingBegin).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');
    if (isBookingBegin) {
      return isToday
        ? timeList.filter((el) => {
            return parseInt(el.value.replace(':', ''), 10) > parseInt(moment().format('HHmm'), 10);
          })
        : timeList;
    } else {
      return isSameDay
        ? timeList.filter(
            (el) =>
              parseInt(el.value.replace(':', ''), 10) >=
              parseInt(moment(bookingBegin).add(4, 'hours').format('HHmm'), 10)
          )
        : timeList;
    }
  };

  generateTime = () => {
    return this.getTimeAvailable();
  };

  get field() {
    return this.props.isBookingBegin ? 'bookingBegin' : 'bookingEnd';
  }
  get value() {
    return this.props[this.field].split(' ')[1];
  }
  onChangeDesktop = (time: any) => this.onChange(time.value);
  onChangeNative = (e: React.ChangeEvent<HTMLSelectElement>) => this.onChange(e.target.value);
  onChange = (time: string) => {
    const nextRange: Range = {
      bookingBegin: this.props.bookingBegin,
      bookingEnd: this.props.bookingEnd,
      [this.field]: `${this.props[this.field].split(' ')[0]} ${time}`
    };
    this.props.onChange({
      bookingBegin: this.getDate(nextRange.bookingBegin, 'bookingBegin'),
      bookingEnd: this.getDate(nextRange.bookingEnd, 'bookingEnd')
    });
    setTimeout(() => {
      this.props.onChange({
        bookingBegin: this.getDate(nextRange.bookingBegin, 'bookingBegin'),
        bookingEnd: this.getDate(nextRange.bookingEnd, 'bookingEnd')
      });
    }, 50);
  };

  render() {
    const timeAvailable = this.generateTime();

    return (
      <div id={this.props.id}>
        {this.props.children(
          <select onChange={this.onChangeNative} value={this.value}>
            {timeAvailable.map((el) => {
              return (
                <option value={el.value} key={el.value}>
                  {el.label}
                </option>
              );
            })}
          </select>
        )}
      </div>
    );
  }
}

export default TimePicker;
