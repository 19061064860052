import { FunctionComponent, useState } from 'react';
import { navigate } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { sendToDataLayer, getDefaultEventCategory, PageSection } from '@utils';
enum SearchType {
  Rental = 'rental',
  RentalWithDriver = 'rental-with-driver'
}
const SEARCH_TAB = [
  {
    type: SearchType.Rental,
    title: 'เช่ารถขับเอง'
  },
  {
    type: SearchType.RentalWithDriver,
    title: 'เช่ารถพร้อมคนขับ'
  }
];

const SearchBoxTab: FunctionComponent<{ pageSection: PageSection }> = ({ pageSection }) => {
  const [active] = useState<SearchType>(SearchType.Rental);
  const handleOnClick = (which: SearchType) => {
    if (which === SearchType.Rental) return;
    sendToDataLayer(undefined, {
      event_category: getDefaultEventCategory(pageSection),
      event_label: 'searching_rentaltype3'
    });
    navigate(`/th/car-with-driver`);
  };
  return (
    <Container>
      <Row>
        {SEARCH_TAB.map((t, i) => (
          <Col
            className={`search-type search-type--${t.type} ${t.type === active ? 'active' : ''}`}
            key={i}
            onClick={() => handleOnClick(t.type)}
          >
            {t.title}
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default SearchBoxTab;
